
import { Options, Vue, setup } from "vue-class-component";
import { RouterView, useRouter, Router } from "vue-router";
import { Dialog, Toast } from "vant";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import conn from "./initIm";
import { ERROR_CODE } from "@/const/errorCode";
import { getMessageFromId } from "@/utils/im";
import { CHAT_TYPE } from "./const";
import { AllState } from "@/store";
import { formatTime } from "@/utils";

@Options({
  components: {
    RouterView
  }
})
export default class App extends Vue {
  app = setup(() => {
    const router: Router = useRouter();
    const isShowApp = ref(false);
    const imToken: string | null = localStorage.getItem("token");
    const imUid: string | null = localStorage.getItem("uid");

    const loginByToken = () => {
      if (imToken && imUid) {
        conn
          .open({
            accessToken: imToken,
            user: imUid
          })
          .then(() => {
            router.push("/chat");
          })
          .catch(() => {
            router.push("/login");
          })
          .finally(() => {
            isShowApp.value = true;
          });
      } else {
        isShowApp.value = true;
        router.push("/login");
      }
    };

    onMounted(() => {
      loginByToken();

      document.oncontextmenu = function (e) {
        // 禁用浏览器菜单
        e.preventDefault();
      };
      const store = useStore<AllState>();
      store.commit("IM/setConnect", conn);

      conn.addEventHandler("Connected", {
        onConnected: () => {
          console.log(`%cDeviceId:${conn.clientResource}`, "color: #abcdef;");
          Toast("连接成功");
        },
        onDisconnected: () => {
          Toast("连接断开");
        }
      });

      conn.addEventHandler("MESSAGE", {
        // onTextMessage: (message) => {
        //   store.commit("IM/pushMessage", {
        //     fromId: getMessageFromId(conn.user, message),
        //     message
        //   });
        // },
        // onImageMessage: (message) => {
        //   store.commit("IM/pushMessage", {
        //     fromId: getMessageFromId(conn.user, message),
        //     message
        //   });
        //   console.log("收到图片消息了", message);
        // },
        // onFileMessage: (message) => {
        //   store.commit("IM/pushMessage", {
        //     fromId: getMessageFromId(conn.user, message),
        //     message
        //   });
        //   console.log("收到附件消息了", message);
        // },
        // onVideoMessage: (message) => {
        //   store.commit("IM/pushMessage", {
        //     fromId: getMessageFromId(conn.user, message),
        //     message
        //   });
        //   console.log("收到视频消息了", message);
        // },
        // onCustomMessage: (message) => {
        //   store.commit("IM/pushMessage", {
        //     fromId: getMessageFromId(conn.user, message),
        //     message
        //   });
        //   console.log("收到自定义消息了", message);
        // },
        onCmdMessage: (message) => {
          store.commit("IM/pushMessage", {
            fromId: getMessageFromId(conn.user, message),
            message
          });
          console.log("收到命令消息了", message);
        },
        // //@ts-ignore
        // onCombineMessage: (message) => {
        //   store.commit("IM/pushMessage", {
        //     fromId: getMessageFromId(conn.user, message),
        //     message
        //   });
        //   console.log("收到合并消息了", message);
        // },
        onMessage: (messages) => {
          messages.forEach((item) => {
            store.commit("IM/pushMessage", {
              fromId: getMessageFromId(conn.user, item),
              message: item
            });
          });
        },
        onModifiedMessage: (message) => {
          store.commit("IM/updateMessage", {
            fromId: getMessageFromId(conn.user, message),
            modifiedMsg: message
          });
        },
        onRecallMessage: (message) => {
          // 撤回消息没有chatType, 所以单聊群组都查一下,然后删除
          store.commit("IM/deleteMessage", {
            fromId: `${CHAT_TYPE.singleChat}${message.from}`,
            id: message.mid
          });

          store.commit("IM/deleteMessage", {
            fromId: `${CHAT_TYPE.groupChat}${message.to}`,
            id: message.mid
          });
          console.log("收到撤回消息了", message);
        },
        onDeliveredMessage: (message) => {
          console.log("收到送达回执消息了", message);
        },
        onChannelMessage: (message) => {
          console.log("收到会话已读回执消息了", message);
        },
        onReceivedMessage: (message) => {
          console.log("收到消息送达服务器消息了", message);
        },
        onReadMessage: (message) => {
          console.log("收到消息已读回执消息了", message);
          // conn.getGroupMsgReadUser({
          //   msgId: message.mid as string, // 消息 ID
          //   groupId: "170933540159489" // 群组 ID
          // }).then((res)=>{
          //   console.log(res, 'res777788888')
          // });
        },
        onStatisticMessage: (message) => {
          console.log("离线消息已读回执消息了", message);
        },
        onContactAgreed: (message) => {
          Toast(`${message.from}已经同意您的好友申请`);
        },
        onContactRefuse: (message) => {
          Toast(`${message.from}拒绝了您的好友申请`);
        },
        onContactDeleted: (message) => {
          Toast(`${message.from}解除了与您的好友关系`);
        },
        onContactInvited: (message) => {
          Dialog.confirm({
            title: `${message.from}请求添加您为好友`,
            message: message.status
          })
            .then(() => {
              conn
                .acceptContactInvite(message.from)
                .then(() => {
                  Toast("接受好友申请成功");
                })
                .catch((e: any) => {
                  console.log("接收好友申请失败", e);
                  Toast("接收好友申请失败");
                });
            })
            .catch(() => {
              conn
                .declineContactInvite(message.from)
                .then(() => {
                  Toast("拒绝好友申请成功");
                })
                .catch((e: any) => {
                  console.log("接收好友申请失败", e);
                  Toast("拒绝好友申请失败");
                });
            });
        }
      });

      conn.addEventHandler("ERROR", {
        onError: (e) => {
          switch (e.message) {
            case ERROR_CODE.noAuth:
              router.push("/login");
              break;
            default:
              break;
          }
        }
      });

      conn.addEventHandler("CHATROOM", {
        onChatroomEvent: (e) => {
          switch (e.operation) {
            case "memberPresence":
              // 当前聊天室在线人数
              console.log(e?.memberCount);
              break;
            case "memberAbsence":
              // 当前聊天室在线人数
              console.log(e?.memberCount);
              break;
            default:
              break;
          }
        }
      });

      conn.addEventHandler("GROUP", {
        onGroupEvent: (e) => {
          if (e.operation === "memberAbsence") {
            console.log(e?.memberCount);
          }
        }
      });

      conn.addEventHandler("SYNC_OFFLINE_MESSAGE", {
        onOfflineMessageSyncStart: () => {
          console.log("开始同步离线消息");
          Toast("开始同步离线消息");
        },
        onOfflineMessageSyncFinish: () => {
          console.log("同步离线消息完成");
          Toast("同步离线消息完成");
        }
      });

      conn.addEventHandler("MESSAGE_PIN", {
        onMessagePinEvent(e) {
          const { messageId, conversationType, conversationId, operation } = e;
          const fromId = `${conversationType}${conversationId}`;

          switch (operation) {
            case "pin":
              store.state.IM.chat?.[fromId]?.messageList.findIndex(
                (item: any) => {
                  if (item.id === messageId) {
                    store.commit("IM/unshiftPinedMessage", {
                      fromId,
                      message: {
                        message: item,
                        operatorId: e.operatorId,
                        pinTime: formatTime(e.time)
                      }
                    });
                  }
                }
              );
              break;
            case "unpin":
              store.commit("IM/deletePinedMessage", {
                fromId,
                messageId
              });
              break;
            default:
              break;
          }
        }
      });
    });
    return {
      isShowApp
    };
  });
}
