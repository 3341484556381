import websdk from "easemob-websdk";
import {
  appKey,
  url,
  restUrl,
  isHttpDNS,
  useCustomConfig,
  useReplacedMessageContents,
  DEFAULT_APPKEY,
  fixedDeviceId,
  loginInfo
} from "@/const/index";
import type { EasemobChat } from "easemob-websdk/Easemob-chat";

const customOption = {
  appKey,
  // delivery: true,
  url: url || `${window.location.protocol}//im-api-v2-hsb.easemob.com/ws`,
  apiUrl: restUrl || `${window.location.protocol}//a1-hsb.easemob.com`,
  useReplacedMessageContents,
  isHttpDNS,
  autoReconnectNumMax: 20,
  isFixedDeviceId: fixedDeviceId
};

const option = {
  appKey: DEFAULT_APPKEY
};

// websdk.logger.enableAll();
// websdk.logger.setConsoleLogVisibility(false);
// websdk.logger.onLog = (log) => {
//   console.log(log, "onLog");
// };
// websdk.logger.disableAll();

const conn = new websdk.connection(
  useCustomConfig ? customOption : option
) as unknown as EasemobChat.Connection;

if (loginInfo) {
  conn.setLoginInfoCustomExt(loginInfo);
}

console.log(
  `%cInitParams:`,
  "color: #abcdef;",
  useCustomConfig ? customOption : option
);

//@ts-ignore
window.websdk = websdk;
//@ts-ignore
window.conn = conn;

export default conn;
