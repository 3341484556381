import { ERROR_CODE } from "@/const/errorCode";
import websdk from "easemob-websdk";
import { EasemobChat } from "easemob-websdk/Easemob-chat";
import conn from "../initIm";
import { Toast } from "vant";
import { appName, CHAT_TYPE } from "@/const/index";

// 发送消息
const deliverMsg = (msg: any) => {
  return conn
    .send(msg)
    .then((res) => {
      msg.id = res.serverMsgId;
      return res;
    })
    .catch((e: EasemobChat.ErrorEvent) => {
      if (e.message === ERROR_CODE.notLogin) {
        console.log("未登录");
      } else if (e.message === ERROR_CODE.sendMsgBlock) {
        Toast("您已被拉入黑名单");
      }
      console.log(e, "发送消息失败");
      throw e;
    });
};

// 撤回消息

const recallMessage = (options: any) => {
  return new Promise((resolve, reject) => {
    conn
      .recallMessage(options)
      .then((res: any) => {
        resolve(res);
      })
      .catch((e: any) => {
        if (e.message === ERROR_CODE.notLogin) {
          console.log("未登录");
        } else if (e.message === ERROR_CODE.recallTimeout) {
          Toast("已超过可撤回时间");
        }
        reject(e);
        console.log(e, "撤回消息失败");
      });
  });
};

// 创建消息
const createMsg = (opt: EasemobChat.CreateMsgType) => {
  // @ts-ignore
  return websdk.message.create(opt);
};

// 格式化file文件
const formatImFile = (file: File) => {
  return {
    filename: file.name,
    filetype: file.type.split("/")[1],
    data: file
  };
};

// 根据channelID获取会话ID
const formatSessionListTo = (from: string) => {
  const channelIdRegex = new RegExp(`${appName}_(\\S*)@|${appName}_(\\S*)`);
  return (
    from.match(channelIdRegex)?.[1] || from.match(channelIdRegex)?.[2]
  )?.replace(channelIdRegex, "");
};

// 获取store fromId
const getMessageFromId = (currentUid: string, message: any) => {
  const { to, from, chatType } = message;
  if (currentUid === message.from) {
    return `${chatType}${to}`;
  }
  return chatType === CHAT_TYPE.groupChat || chatType === CHAT_TYPE.chatRoom
    ? `${chatType}${to}`
    : `${chatType}${from}`;
};

export {
  deliverMsg,
  formatImFile,
  createMsg,
  recallMessage,
  formatSessionListTo,
  getMessageFromId
};
