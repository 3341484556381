const DEFAULT_APPKEY = "easemob#easeim";
const appKey: string = localStorage.getItem("appkey") || DEFAULT_APPKEY;
const useReplacedMessageContents =
  localStorage.getItem("useReplacedMessageContents") === "true";
const isHttpDNS = localStorage.getItem("isHttpDNS") === "true";
const useCustomConfig = localStorage.getItem("useCustomConfig") === "true";
const tokenLogin = localStorage.getItem("isTokenLogin") === "true";
const url = localStorage.getItem("url");
const restUrl = localStorage.getItem("restUrl");
const fixedDeviceId = localStorage.getItem("isFixedDeviceId") !== "false";
const loginInfo = localStorage.getItem("loginInfo");

const appName = appKey.split("#")[1];

const GROUP_SESSION = "conference"; // 会话列表群组标识

enum MSG_TYPE {
  read = "read",
  delivery = "delivery",
  channel = "channel",
  txt = "txt",
  cmd = "cmd",
  custom = "custom",
  loc = "loc",
  img = "img",
  audio = "audio",
  file = "file",
  video = "video",
  combine = "combine"
}

enum CHAT_TYPE {
  singleChat = "singleChat",
  groupChat = "groupChat",
  chatRoom = "chatRoom"
}

enum MSG_OPT_TYPE {
  recall = "撤回",
  modify = "编辑",
  pin = "置顶",
  unpin = "取消置顶"
  // read = "已读"
}

export {
  appKey,
  GROUP_SESSION,
  DEFAULT_APPKEY,
  appName,
  useReplacedMessageContents,
  isHttpDNS,
  useCustomConfig,
  url,
  restUrl,
  tokenLogin,
  fixedDeviceId,
  loginInfo,
  MSG_TYPE,
  CHAT_TYPE,
  MSG_OPT_TYPE
};
