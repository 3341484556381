import dayjs from "dayjs";

const formatTime = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  template: string = "YYYY-MM-DD"
) => {
  return dayjs(date).format(template);
};

const scrollToBottom = (dom: HTMLElement | null) => {
  dom?.scrollTo({
    top: dom?.scrollHeight
  });
};
const getQueryParams = (url: string) => {
  return url.split("&").reduce((result, item) => {
    const parts = item.split("=");
    //@ts-ignore
    result[parts[0]] = parts[1];
    return result;
  }, {});
};

export { formatTime, scrollToBottom, getQueryParams };
