import { Module } from "vuex";
import { EasemobChat } from "easemob-websdk/Easemob-chat";
import conn from "@/initIm";
interface ChatMap {
  [key: string]: any;
}
export interface IMState {
  connect: EasemobChat.Connection;
  chat: ChatMap;
  pin: ChatMap;
  pinMessageIdsMap: Record<string, Array<string>>;
}

const im: Module<IMState, IMState> = {
  namespaced: true,
  state: {
    connect: {} as EasemobChat.Connection,
    chat: {},
    pin: {},
    pinMessageIdsMap: {}
  },
  mutations: {
    setConnect(state, conn) {
      state.connect = conn;
    },
    pushMessage(state, { fromId, message }) {
      if (Object.prototype.hasOwnProperty.call(state.chat, fromId)) {
        state.chat[fromId].messageList.push(message);
      } else {
        state.chat[fromId] = {
          messageList: [message]
        };
      }
    },
    deleteMessage(state, { fromId, id }) {
      if (state.chat[fromId]) {
        const idx = state.chat[fromId].messageList.findIndex(
          (item: EasemobChat.MessageBody) => {
            return item.id === id;
          }
        );
        if (idx > -1) {
          state.chat[fromId].messageList.splice(idx, 1);
        }
      }
    },
    updateMessage(
      state,
      params: { fromId: string; modifiedMsg: EasemobChat.ModifiedMsg }
    ) {
      const { fromId, modifiedMsg } = params;
      if (state.chat[fromId]) {
        const idx = state.chat[fromId].messageList.findIndex(
          (item: EasemobChat.MessageBody) => {
            return item.id === modifiedMsg.id;
          }
        );
        if (idx > -1) {
          if (modifiedMsg.type === "txt") {
            state.chat[fromId].messageList[idx].msg = modifiedMsg.msg;
            state.chat[fromId].messageList[idx].modifiedInfo =
              modifiedMsg.modifiedInfo;
          } else if (modifiedMsg.type === "custom") {
            state.chat[fromId].messageList[idx] = {
              ...modifiedMsg
            };
          }
        }
      }
    },
    clearPinnedMessage(state, { fromId }) {
      if (state.pin[fromId]) {
        state.pin[fromId] = {
          messageList: []
        };
      }
    },
    pushPinedMessage(state, { fromId, message }) {
      if (Object.prototype.hasOwnProperty.call(state.pin, fromId)) {
        state.pin[fromId].messageList.push(message);
      } else {
        state.pin[fromId] = {
          messageList: [message]
        };
      }
      state.pinMessageIdsMap[fromId] = state.pin?.[fromId]?.messageList.map(
        (item: any) => {
          return item.message.id;
        }
      );
    },
    unshiftPinedMessage(state, { fromId, message }) {
      if (Object.prototype.hasOwnProperty.call(state.pin, fromId)) {
        state.pin[fromId].messageList.unshift(message);
      } else {
        state.pin[fromId] = {
          messageList: [message]
        };
      }
      state.pinMessageIdsMap[fromId] = state.pin?.[fromId]?.messageList.map(
        (item: any) => {
          return item.message.id;
        }
      );
    },
    deletePinedMessage(state, { fromId, id }) {
      if (state.pin[fromId]) {
        const idx = state.pin[fromId].messageList.findIndex(
          (item: EasemobChat.MessageBody) => {
            return item.id === id;
          }
        );
        if (idx > -1) {
          state.pin[fromId].messageList.splice(idx, 1);
        }
      }
      state.pinMessageIdsMap[fromId] = state.pin?.[fromId]?.messageList.map(
        (item: any) => {
          return item.message.id;
        }
      );
    },
    updatePinedMessage(state, { fromId, modifiedMsg }) {
      if (state.pin[fromId]) {
        const idx = state.pin[fromId].messageList.findIndex(
          (item: EasemobChat.MessageBody) => {
            return item.id === modifiedMsg.id;
          }
        );
        if (idx > -1) {
          state.pin[fromId].messageList[idx].msg = modifiedMsg.msg;
          state.pin[fromId].messageList[idx].modifiedInfo =
            modifiedMsg.modifiedInfo;
        }
      }
      state.pinMessageIdsMap[fromId] = state.pin?.[fromId]?.messageList.map(
        (item: any) => {
          return item.message.id;
        }
      );
    }
  }
};

export default im;
